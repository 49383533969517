import React from 'react';

import './Map.css';
import { colorArray, setUpMap } from './initialization';
import { markersRenderer } from './plugins/markersRenderer';

import { L } from '../../types/map';
import { TVesselData } from '../../types/data';
import { TVesselShort } from '../../types/vessel';

type Props = {
  tracks: TVesselData[];
};


class Map extends React.PureComponent<Props> {
  private static DOM_ID = 'map';
  private readonly colors = [...colorArray].reverse();
  private map: L.Map;
  private tracks: L.Polyline[] = [];

  componentDidMount(): void {
    this.map = setUpMap(Map.DOM_ID);
    this.updateMarkers();
  }

  componentDidUpdate({ tracks }: Props) {
    if (this.props.tracks !== tracks) {
      this.updateMarkers();
    }
  }

  updateMarkers(): void {
    const { tracks } = this.props;
    const edgePoints = [];
    this.clearTracks();

    tracks.forEach((track: TVesselData, index) => {
      const options = {
        color: this.colors[index],
        weight: 2
      };
      const pl = window.L.polyline(track.data, options).addTo(this.map);
      this.tracks.push(pl);
      edgePoints.push(track.data[track.data.length - 1]);
    });

    const infos = tracks.map((t: TVesselData, index): TVesselShort => ({
      colors: [colorArray[index], colorArray[index]],
      name: t.name
    }));

    markersRenderer(edgePoints.filter(Boolean), this.map, infos);
  }

  clearTracks(): void {
    this.tracks.forEach((track: L.Polyline) => track.remove());
    this.tracks = [];
  }

  render() {
    return (
      <div className="Map" id={Map.DOM_ID}>
        <img alt="" className="Map__logo" src="FFMapLogo_color.svg" />
      </div>
    );
  }
}

export default Map;
