export enum EPlayRate {
  NORMAL = 1,
  x3 = 3,
  x10 = 10,
  x100 = 100
}

export enum EPlayMode {
  STOP = 'STOP',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  FAST_FORWARD = 'FAST_FORWARD',
  REWIND = 'REWIND'
}

export type TTimeFlowState = {
  currentIndex: number;
  playMode: EPlayMode;
  playRate: EPlayRate;
};

export type TPlayerState = TTimeFlowState & {
  endTime: number;
  startTime: number;
};
