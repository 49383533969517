import React from 'react';

import DataLoader from './services/DataLoader';
import TrackPlayer from './components/TrackPlayer/TrackPlayer';
import { TVesselData } from './types/data';

type State = {
  endTime: number;
  startTime: number;
  vessels: TVesselData[];
};


export class App extends React.PureComponent<{}, State> {
  state: State = {
    endTime: 0,
    startTime: 0,
    vessels: []
  };

  componentDidMount() {
    new DataLoader(this.handleResponse);
  }

  handleResponse = (vessels: TVesselData[]) => {
    const starts = [];
    const ends = [];

    vessels.forEach((v: TVesselData) => {
      starts.push(v.data[0].timestamp);
      ends.push(v.data[v.data.length - 1].timestamp);
    });

    this.setState({
      endTime: Math.max(...ends),
      startTime: Math.min(...starts),
      vessels: vessels
    });
  };

  render() {
    return (
      <div className="App">
        <TrackPlayer {...this.state} />
      </div>
    );
  }
}
