import { TAnimationConfig } from '../types/data';

export const data: Array<TAnimationConfig> = [
  {
    id: 'edfd0bf5a819',
    startDate: new Date('2019-01-01T00:00:00'),
    endDate: new Date('2019-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 371,
        vesselName: 'Beinur'
      }
    ]
  },
  {
    id: '575e35166efb',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 371,
        vesselName: 'Beinur'
      }
    ]
  },
  {
    id: '5998a0e39fe7',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-10-10T23:59:59'),
    vessels: [
      {
        vesselId: 371,
        vesselName: 'Beinur'
      }
    ]
  },
  {
    id: 'f5fe73880685',
    startDate: new Date('2019-01-01T00:00:00'),
    endDate: new Date('2019-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 365,
        vesselName: 'Asbjørn'
      }
    ]
  },
  {
    id: 'c2cffbc31ed9',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 365,
        vesselName: 'Asbjørn'
      }
    ]
  },
  {
    id: 'd28401efeb99',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-10-10T23:59:59'),
    vessels: [
      {
        vesselId: 365,
        vesselName: 'Asbjørn'
      }
    ]
  },
  {
    id: '91d68c728b90',
    startDate: new Date('2019-01-01T00:00:00'),
    endDate: new Date('2019-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 152,
        vesselName: 'Atlantic Star'
      }
    ]
  },
  {
    id: '29cae9e806bd',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 152,
        vesselName: 'Atlantic Star'
      }
    ]
  },
  {
    id: '199d7cfab0b3',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-09-25T23:59:59'),
    vessels: [
      {
        vesselId: 152,
        vesselName: 'Atlantic Star'
      }
    ]
  },
  {
    id: 'a944e07f4377',
    startDate: new Date('2019-01-01T00:00:00'),
    endDate: new Date('2019-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 1013,
        vesselName: 'Nokasa'
      }
    ]
  },
  {
    id: '870443c82c01',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 1013,
        vesselName: 'Nokasa'
      }
    ]
  },
  {
    id: 'a1be3b56c557',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-10-15T23:59:59'),
    vessels: [
      {
        vesselId: 1013,
        vesselName: 'Nokasa'
      }
    ]
  },
  {
    id: '2dbeb58fe21e',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 1587,
        vesselName: 'Aine'
      }
    ]
  },
  {
    id: 'acb1203c2253',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-10-01T23:59:59'),
    vessels: [
      {
        vesselId: 1587,
        vesselName: 'Aine'
      }
    ]
  },
  {
    id: 'f1c2bf107bc5',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 364,
        vesselName: 'GINNETON'
      }
    ]
  },
  {
    id: '4681ada6378c',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 372,
        vesselName: 'Ceton'
      }
    ]
  },
  {
    id: 'fc26c6290610',
    startDate: new Date('2021-06-01T00:00:00'),
    endDate: new Date('2021-07-01T23:59:59'),
    vessels: [
      {
        vesselId: 153,
        vesselName: 'Klakkur'
      }
    ]
  },
  {
    id: '991e882bd0bd',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 272,
        vesselName: 'Nordbas'
      }
    ]
  },
  {
    id: '6814fcddd78d',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-10-01T23:59:59'),
    vessels: [
      {
        vesselId: 272,
        vesselName: 'Nordbas'
      }
    ]
  },
  {
    id: 'bd5b010bc71c',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-31T23:59:59'),
    vessels: [
      {
        vesselId: 151,
        vesselName: 'Nordstar'
      }
    ]
  },
  {
    id: '330bc438410e',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-10-01T23:59:59'),
    vessels: [
      {
        vesselId: 151,
        vesselName: 'Nordstar'
      }
    ]
  },
  {
    id: '053d9ed8b54d',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2021-01-01T00:00:00'),
    vessels: [
      {
        vesselId: 732,
        vesselName: 'Kildin'
      },
      {
        vesselId: 610,
        vesselName: 'Boris Zaytsev'
      },
      {
        vesselId: 702,
        vesselName: 'Yakov Gunin'
      },
      {
        vesselId: 659,
        vesselName: 'Nordkap'
      },
      {
        vesselId: 675,
        vesselName: 'Rybak'
      },
      {
        vesselId: 690,
        vesselName: 'Vasiliy Golovnin'
      },
      {
        vesselId: 379,
        vesselName: 'Kpitan Bulatov'
      },
      {
        vesselId: 697,
        vesselName: 'Vitus Bering'
      },
      {
        vesselId: 626,
        vesselName: 'Kapitan Dolgikh'
      },
      {
        vesselId: 656,
        vesselName: 'Mys Slepikovskogo'
      },
      {
        vesselId: 650,
        vesselName: 'Mys Gozdeva'
      },
      {
        vesselId: 603,
        vesselName: 'Aquamarine'
      },
      {
        vesselId: 649,
        vesselName: 'Mys Chikhacheva'
      },
      {
        vesselId: 906,
        vesselName: 'Shpitsbergen'
      },
      {
        vesselId: 557,
        vesselName: 'Barentsburg'
      },
      {
        vesselId: 564,
        vesselName: 'Polar Star'
      },
      {
        vesselId: 651,
        vesselName: 'Mys Korsakova'
      },
      {
        vesselId: 655,
        vesselName: 'Mys Sheltinga'
      },
      {
        vesselId: 634,
        vesselName: 'Karelia II'
      },
      {
        vesselId: 629,
        vesselName: 'Kapitan Gromtsev'
      }
    ]
  },
  {
    id: '6eb33c977dcd',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-10-01T00:00:00'),
    vessels: [
      {
        vesselId: 732,
        vesselName: 'Kildin'
      },
      {
        vesselId: 610,
        vesselName: 'Boris Zaytsev'
      },
      {
        vesselId: 702,
        vesselName: 'Yakov Gunin'
      },
      {
        vesselId: 659,
        vesselName: 'Nordkap'
      },
      {
        vesselId: 675,
        vesselName: 'Rybak'
      },
      {
        vesselId: 690,
        vesselName: 'Vasiliy Golovnin'
      },
      {
        vesselId: 379,
        vesselName: 'Kpitan Bulatov'
      },
      {
        vesselId: 697,
        vesselName: 'Vitus Bering'
      },
      {
        vesselId: 626,
        vesselName: 'Kapitan Dolgikh'
      },
      {
        vesselId: 656,
        vesselName: 'Mys Slepikovskogo'
      },
      {
        vesselId: 650,
        vesselName: 'Mys Gozdeva'
      },
      {
        vesselId: 603,
        vesselName: 'Aquamarine'
      },
      {
        vesselId: 649,
        vesselName: 'Mys Chikhacheva'
      },
      {
        vesselId: 906,
        vesselName: 'Shpitsbergen'
      },
      {
        vesselId: 557,
        vesselName: 'Barentsburg'
      },
      {
        vesselId: 564,
        vesselName: 'Polar Star'
      },
      {
        vesselId: 651,
        vesselName: 'Mys Korsakova'
      },
      {
        vesselId: 655,
        vesselName: 'Mys Sheltinga'
      },
      {
        vesselId: 634,
        vesselName: 'Karelia II'
      },
      {
        vesselId: 629,
        vesselName: 'Kapitan Gromtsev'
      }
    ]
  },
  {
    id: 'e2832513e767',
    startDate: new Date('2021-11-08T00:00:00'),
    endDate: new Date('2021-11-11T23:59:59'),
    vessels: [
      {
        vesselId: 145,
        vesselName: 'Havfisk'
      }
    ]
  },
  {
    id: '47cbf6b1f319',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-12-13T23:59:59'),
    vessels: [
      {
        vesselId: 132,
        vesselName: 'M. Ytterstad'
      },
      {
        vesselId: 130,
        vesselName: 'Rødholmen'
      }
    ]
  },
  {
    id: '2540fe34c248',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-12-17T23:59:59'),
    vessels: [
      {
        vesselId: 107,
        vesselName: 'Arctic Swan'
      },
      {
        vesselId: 108,
        vesselName: 'Gadus Poseidon'
      },
      {
        vesselId: 109,
        vesselName: 'Gadus Neptun'
      },
      {
        vesselId: 111,
        vesselName: 'Rypefjord'
      },
      {
        vesselId: 112,
        vesselName: 'Hermes'
      },
      {
        vesselId: 113,
        vesselName: 'Ingrid Majala'
      },
      {
        vesselId: 116,
        vesselName: 'J.Bergvoll'
      },
      {
        vesselId: 117,
        vesselName: 'Synes'
      },
      {
        vesselId: 118,
        vesselName: 'FRANTSEN JUNIOR'
      },
      {
        vesselId: 119,
        vesselName: 'LANGENES'
      },
      {
        vesselId: 124,
        vesselName: 'Nordfisk'
      },
      {
        vesselId: 125,
        vesselName: 'Steinevik'
      },
      {
        vesselId: 126,
        vesselName: 'Kvannøy'
      },
      {
        vesselId: 127,
        vesselName: 'Senior (NO)'
      },
      {
        vesselId: 128,
        vesselName: 'Havtind'
      },
      {
        vesselId: 129,
        vesselName: 'Vesttind'
      },
      {
        vesselId: 130,
        vesselName: 'Rødholmen'
      },
      {
        vesselId: 131,
        vesselName: 'Øygarden'
      },
      {
        vesselId: 132,
        vesselName: 'M. Ytterstad'
      },
      {
        vesselId: 133,
        vesselName: 'Selvåg Senior'
      },
      {
        vesselId: 134,
        vesselName: 'Holmøy'
      },
      {
        vesselId: 136,
        vesselName: 'Gadus Njord'
      },
      {
        vesselId: 139,
        vesselName: 'Prestfjord'
      },
      {
        vesselId: 140,
        vesselName: 'Trønderbas'
      },
      {
        vesselId: 141,
        vesselName: 'Svanaug Elise'
      },
      {
        vesselId: 142,
        vesselName: 'Nybo'
      },
      {
        vesselId: 143,
        vesselName: 'Ishavet'
      },
      {
        vesselId: 146,
        vesselName: 'Havstål'
      },
      {
        vesselId: 147,
        vesselName: 'O. Husby'
      },
      {
        vesselId: 149,
        vesselName: 'Atlantic Viking'
      },
      {
        vesselId: 150,
        vesselName: 'Molnes'
      },
      {
        vesselId: 151,
        vesselName: 'Nordstar'
      },
      {
        vesselId: 152,
        vesselName: 'Atlantic Star'
      },
      {
        vesselId: 154,
        vesselName: 'Havstrand'
      },
      {
        vesselId: 155,
        vesselName: 'Havbryn'
      },
      {
        vesselId: 157,
        vesselName: 'Fiskeskjer'
      },
      {
        vesselId: 158,
        vesselName: 'Teigenes'
      },
      {
        vesselId: 159,
        vesselName: 'Leinebjørn'
      },
      {
        vesselId: 161,
        vesselName: 'Kings Bay'
      },
      {
        vesselId: 162,
        vesselName: 'Eros'
      },
      {
        vesselId: 163,
        vesselName: 'Gollenes'
      },
      {
        vesselId: 164,
        vesselName: 'Smaragd (NO)'
      },
      {
        vesselId: 165,
        vesselName: 'Rogne'
      },
      {
        vesselId: 166,
        vesselName: 'Remøy'
      },
      {
        vesselId: 167,
        vesselName: 'SJØBRIS'
      },
      {
        vesselId: 168,
        vesselName: 'Christina E'
      },
      {
        vesselId: 169,
        vesselName: 'Andrea L'
      },
      {
        vesselId: 170,
        vesselName: 'Herøyhav'
      },
      {
        vesselId: 177,
        vesselName: 'Havsnurp'
      },
      {
        vesselId: 178,
        vesselName: 'Fiskebank'
      },
      {
        vesselId: 179,
        vesselName: 'Dyrnesvåg'
      },
      {
        vesselId: 180,
        vesselName: 'Ramoen'
      },
      {
        vesselId: 181,
        vesselName: 'Sæbjørn'
      },
      {
        vesselId: 182,
        vesselName: 'Vestfart'
      },
      {
        vesselId: 183,
        vesselName: 'Fiskebas'
      },
      {
        vesselId: 186,
        vesselName: 'Fonnes'
      },
      {
        vesselId: 187,
        vesselName: 'Harvest'
      },
      {
        vesselId: 188,
        vesselName: 'RADEK'
      },
      {
        vesselId: 189,
        vesselName: 'Vendla'
      },
      {
        vesselId: 191,
        vesselName: 'Knester'
      },
      {
        vesselId: 193,
        vesselName: 'Vestviking'
      },
      {
        vesselId: 195,
        vesselName: 'Manon'
      },
      {
        vesselId: 198,
        vesselName: 'Gardar'
      },
      {
        vesselId: 199,
        vesselName: 'H. Østervold'
      },
      {
        vesselId: 200,
        vesselName: 'Talbor'
      },
      {
        vesselId: 201,
        vesselName: 'Malene S'
      },
      {
        vesselId: 202,
        vesselName: 'Birkeland'
      },
      {
        vesselId: 203,
        vesselName: 'Bømmelfjord'
      },
      {
        vesselId: 204,
        vesselName: 'Østerbris'
      },
      {
        vesselId: 208,
        vesselName: 'NORDERVEG'
      },
      {
        vesselId: 209,
        vesselName: 'Storeknut'
      },
      {
        vesselId: 210,
        vesselName: 'Trygvason'
      },
      {
        vesselId: 211,
        vesselName: 'Lønningen'
      },
      {
        vesselId: 212,
        vesselName: 'Elisabeth'
      },
      {
        vesselId: 213,
        vesselName: 'Bømmelbas'
      },
      {
        vesselId: 214,
        vesselName: 'Brennholm'
      },
      {
        vesselId: 219,
        vesselName: 'Havdrøn'
      },
      {
        vesselId: 220,
        vesselName: 'Ligrunn'
      },
      {
        vesselId: 221,
        vesselName: 'Morten Einar'
      },
      {
        vesselId: 224,
        vesselName: 'Hargun'
      },
      {
        vesselId: 225,
        vesselName: 'Røttingøy'
      },
      {
        vesselId: 226,
        vesselName: 'Krossfjord'
      },
      {
        vesselId: 227,
        vesselName: 'Havglans'
      },
      {
        vesselId: 228,
        vesselName: 'Roaldsen'
      },
      {
        vesselId: 229,
        vesselName: 'Sydvest'
      },
      {
        vesselId: 230,
        vesselName: 'Vea'
      },
      {
        vesselId: 272,
        vesselName: 'Nordbas'
      },
      {
        vesselId: 335,
        vesselName: 'Langøy'
      },
      {
        vesselId: 336,
        vesselName: 'Granit'
      },
      {
        vesselId: 343,
        vesselName: 'Loran'
      },
      {
        vesselId: 345,
        vesselName: 'Leinebris'
      },
      {
        vesselId: 346,
        vesselName: 'Gambler'
      },
      {
        vesselId: 355,
        vesselName: 'Nordtind'
      },
      {
        vesselId: 367,
        vesselName: 'Herøyfjord'
      },
      {
        vesselId: 370,
        vesselName: 'Slaatterøy'
      },
      {
        vesselId: 427,
        vesselName: 'Endre Dyrøy'
      },
      {
        vesselId: 431,
        vesselName: 'Gerda Marie'
      },
      {
        vesselId: 584,
        vesselName: 'Veidar'
      },
      {
        vesselId: 709,
        vesselName: 'Lønnøy'
      },
      {
        vesselId: 710,
        vesselName: 'Båtsfjord'
      },
      {
        vesselId: 711,
        vesselName: 'Kågtind II'
      },
      {
        vesselId: 714,
        vesselName: 'Nordsjøbas'
      },
      {
        vesselId: 718,
        vesselName: 'Seir'
      },
      {
        vesselId: 745,
        vesselName: 'DELFIN (LMTR)'
      },
      {
        vesselId: 747,
        vesselName: 'Doggi'
      },
      {
        vesselId: 749,
        vesselName: 'VALDIMAR H'
      },
      {
        vesselId: 759,
        vesselName: 'Grimsholm'
      },
      {
        vesselId: 767,
        vesselName: 'Straumberg'
      },
      {
        vesselId: 774,
        vesselName: 'KETLIN'
      },
      {
        vesselId: 781,
        vesselName: 'Sævar jr.'
      },
      {
        vesselId: 787,
        vesselName: 'Storm 1'
      },
      {
        vesselId: 788,
        vesselName: 'Østerhav'
      },
      {
        vesselId: 791,
        vesselName: 'VESTFISK (NO)'
      },
      {
        vesselId: 792,
        vesselName: 'Roaldnes'
      },
      {
        vesselId: 793,
        vesselName: 'Nesbakk'
      },
      {
        vesselId: 799,
        vesselName: 'Haltentrål'
      },
      {
        vesselId: 800,
        vesselName: 'Koralhav'
      },
      {
        vesselId: 807,
        vesselName: 'Fiskenes'
      },
      {
        vesselId: 808,
        vesselName: 'Vonar'
      },
      {
        vesselId: 809,
        vesselName: 'KATO'
      },
      {
        vesselId: 811,
        vesselName: 'SJØVÆR'
      },
      {
        vesselId: 815,
        vesselName: 'Veststeinen'
      },
      {
        vesselId: 820,
        vesselName: 'KORALEN'
      },
      {
        vesselId: 821,
        vesselName: 'Kap Farvel'
      },
      {
        vesselId: 822,
        vesselName: 'Fjellmøy'
      },
      {
        vesselId: 825,
        vesselName: 'Tobis'
      },
      {
        vesselId: 826,
        vesselName: 'Vestliner'
      },
      {
        vesselId: 830,
        vesselName: 'Krossøy'
      },
      {
        vesselId: 953,
        vesselName: 'Tønsnes'
      },
      {
        vesselId: 985,
        vesselName: 'Brusøyskjær'
      },
      {
        vesselId: 1009,
        vesselName: 'Strand Senior'
      },
      {
        vesselId: 1013,
        vesselName: 'Nokasa'
      },
      {
        vesselId: 1095,
        vesselName: 'Rav'
      },
      {
        vesselId: 1121,
        vesselName: 'Enterprise (NO)'
      },
      {
        vesselId: 1153,
        vesselName: 'BERGHOLM'
      },
      {
        vesselId: 1154,
        vesselName: 'FANØYVÅG'
      },
      {
        vesselId: 1250,
        vesselName: 'Havfjord'
      },
      {
        vesselId: 1500,
        vesselName: 'Mostein 1'
      },
      {
        vesselId: 1529,
        vesselName: 'Odd Lundberg'
      },
      {
        vesselId: 1530,
        vesselName: 'Cetus'
      },
      {
        vesselId: 1941,
        vesselName: 'Frøyanes Junior'
      },
      {
        vesselId: 1942,
        vesselName: 'Vestkapp'
      },
      {
        vesselId: 1943,
        vesselName: 'Gunnar Langva'
      },
      {
        vesselId: 1996,
        vesselName: 'ATLANTIC'
      },
      {
        vesselId: 2053,
        vesselName: 'Kongsfjord'
      },
      {
        vesselId: 2110,
        vesselName: 'TRYGVE B'
      },
      {
        vesselId: 2135,
        vesselName: 'Senja'
      },
      {
        vesselId: 2874,
        vesselName: 'Astrid (NO)'
      },
      {
        vesselId: 2929,
        vesselName: 'MOKSTEIN'
      },
      {
        vesselId: 2944,
        vesselName: 'TENOR (NO)'
      },
      {
        vesselId: 2950,
        vesselName: 'Geir'
      },
      {
        vesselId: 3444,
        vesselName: 'Olympic Prawn'
      },
      {
        vesselId: 3460,
        vesselName: 'Sunderøy'
      },
      {
        vesselId: 3772,
        vesselName: 'Libas'
      },
      {
        vesselId: 3816,
        vesselName: 'Havskjer'
      },
      {
        vesselId: 3848,
        vesselName: 'Hardhaus'
      },
      {
        vesselId: 3872,
        vesselName: 'Østerfjord (newbuilding - 2021)'
      },
      {
        vesselId: 3989,
        vesselName: 'Charmi'
      },
      {
        vesselId: 4001,
        vesselName: 'Vikingbank'
      },
      {
        vesselId: 4043,
        vesselName: 'Magne Arvesen'
      },
      {
        vesselId: 4239,
        vesselName: 'Hallvardson'
      }
    ]
  },
  {
    id: 'd110baccbed8',
    startDate: new Date('2021-09-14T23:00:00'),
    endDate: new Date('2021-09-16T01:59:59'),
    vessels: [
      {
        vesselId: 152,
        vesselName: 'Atlantic Star'
      }
    ]
  },
  {
    id: '83d8e5fe5c86',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-12-17T23:59:59'),
    vessels: [
      {
        vesselId: 1,
        vesselName: 'Akraberg'
      },
      {
        vesselId: 2,
        vesselName: 'ENNIBERG'
      },
      {
        vesselId: 3,
        vesselName: 'Gadus'
      },
      {
        vesselId: 5,
        vesselName: 'ARCTIC VIKING'
      },
      {
        vesselId: 8,
        vesselName: 'Fagraberg'
      },
      {
        vesselId: 9,
        vesselName: 'Finnur Fríði'
      },
      {
        vesselId: 10,
        vesselName: 'JUPITER (FO)'
      },
      {
        vesselId: 11,
        vesselName: 'Nordborg'
      },
      {
        vesselId: 13,
        vesselName: 'Tróndur í Gøtu'
      },
      {
        vesselId: 14,
        vesselName: 'Tummas T'
      },
      {
        vesselId: 15,
        vesselName: 'Norðingur'
      },
      {
        vesselId: 145,
        vesselName: 'Birita'
      },
      {
        vesselId: 153,
        vesselName: 'Klakkur'
      },
      {
        vesselId: 174,
        vesselName: 'Katrin Jóhanna'
      },
      {
        vesselId: 247,
        vesselName: 'Christian'
      },
      {
        vesselId: 310,
        vesselName: 'Ango'
      },
      {
        vesselId: 311,
        vesselName: 'Høgaberg'
      },
      {
        vesselId: 312,
        vesselName: 'Borgarin'
      },
      {
        vesselId: 357,
        vesselName: 'Hoyvík'
      },
      {
        vesselId: 359,
        vesselName: 'Arctic Voyager'
      },
      {
        vesselId: 369,
        vesselName: 'Gøtunes'
      },
      {
        vesselId: 578,
        vesselName: 'Sjúrðarberg'
      },
      {
        vesselId: 581,
        vesselName: 'Kambur'
      },
      {
        vesselId: 784,
        vesselName: 'JOGVAN I'
      },
      {
        vesselId: 795,
        vesselName: 'Pison'
      },
      {
        vesselId: 907,
        vesselName: 'STEINTÓR'
      },
      {
        vesselId: 908,
        vesselName: 'Borðoyarnes'
      },
      {
        vesselId: 909,
        vesselName: 'TÚGVUSTEINUR'
      },
      {
        vesselId: 910,
        vesselName: 'Safir'
      },
      {
        vesselId: 911,
        vesselName: 'OKNIN'
      },
      {
        vesselId: 912,
        vesselName: 'Eysturbúgvin'
      },
      {
        vesselId: 913,
        vesselName: 'Smaragd'
      },
      {
        vesselId: 914,
        vesselName: 'VÁÐASTEINUR'
      },
      {
        vesselId: 915,
        vesselName: 'SKØRIN'
      },
      {
        vesselId: 916,
        vesselName: 'VESTURLEIKI'
      },
      {
        vesselId: 917,
        vesselName: 'NÚPUR'
      },
      {
        vesselId: 919,
        vesselName: 'STJØRNAN'
      },
      {
        vesselId: 920,
        vesselName: 'THOR (FO)'
      },
      {
        vesselId: 921,
        vesselName: 'HAMRANES'
      },
      {
        vesselId: 922,
        vesselName: 'SUÐRINGUR'
      },
      {
        vesselId: 923,
        vesselName: 'Stelkur'
      },
      {
        vesselId: 928,
        vesselName: 'Fiskimúli'
      },
      {
        vesselId: 929,
        vesselName: 'SKORABERG'
      },
      {
        vesselId: 930,
        vesselName: 'POLARHAV (FO)'
      },
      {
        vesselId: 931,
        vesselName: 'BAKUR'
      },
      {
        vesselId: 932,
        vesselName: 'Stapin'
      },
      {
        vesselId: 935,
        vesselName: 'Jákup B'
      },
      {
        vesselId: 936,
        vesselName: 'KVIKK'
      },
      {
        vesselId: 937,
        vesselName: 'Heykur'
      },
      {
        vesselId: 938,
        vesselName: 'Jaspis'
      },
      {
        vesselId: 939,
        vesselName: 'FRAM'
      },
      {
        vesselId: 940,
        vesselName: 'Polarstjørnan'
      },
      {
        vesselId: 941,
        vesselName: 'EIVIND'
      },
      {
        vesselId: 942,
        vesselName: 'Fuglberg'
      },
      {
        vesselId: 943,
        vesselName: 'Ametyst'
      },
      {
        vesselId: 944,
        vesselName: 'Brestir'
      },
      {
        vesselId: 945,
        vesselName: 'Falkur'
      },
      {
        vesselId: 948,
        vesselName: 'BREIÐANES'
      },
      {
        vesselId: 950,
        vesselName: 'Havbúgvin'
      },
      {
        vesselId: 952,
        vesselName: 'Sandshavið'
      },
      {
        vesselId: 955,
        vesselName: 'Vesturbúgvin'
      },
      {
        vesselId: 956,
        vesselName: 'Vesturhavið'
      },
      {
        vesselId: 3821,
        vesselName: 'Christian í Grótinum (Newbuilding - 2022)'
      },
      {
        vesselId: 4074,
        vesselName: 'Carlton'
      }
    ]
  },
  {
    id: '24676cf16ca2',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-12-17T23:59:59'),
    vessels: [
      {
        vesselId: 156,
        vesselName: 'Svanur RE 45'
      },
      {
        vesselId: 205,
        vesselName: 'Alsey VE 2'
      },
      {
        vesselId: 239,
        vesselName: 'Venus'
      },
      {
        vesselId: 240,
        vesselName: 'Beitir'
      },
      {
        vesselId: 241,
        vesselName: 'Vikingur'
      },
      {
        vesselId: 242,
        vesselName: 'Bardi'
      },
      {
        vesselId: 243,
        vesselName: 'Bjarni Ólafsson'
      },
      {
        vesselId: 244,
        vesselName: 'Adalsteinn Jonsson'
      },
      {
        vesselId: 245,
        vesselName: 'Hoffell'
      },
      {
        vesselId: 246,
        vesselName: 'Heimaey'
      },
      {
        vesselId: 248,
        vesselName: 'Sigurdur'
      },
      {
        vesselId: 250,
        vesselName: 'Hákon'
      },
      {
        vesselId: 251,
        vesselName: 'Jón Kjartansson SU311'
      },
      {
        vesselId: 252,
        vesselName: 'Ísleifur'
      },
      {
        vesselId: 254,
        vesselName: 'Huginn'
      },
      {
        vesselId: 257,
        vesselName: 'Ásgrímur Halldórsson'
      },
      {
        vesselId: 258,
        vesselName: 'Jona Edvalds'
      },
      {
        vesselId: 260,
        vesselName: 'Sighvatur Bjarnarson VE'
      },
      {
        vesselId: 263,
        vesselName: 'Vigri RE'
      },
      {
        vesselId: 265,
        vesselName: 'Hrafn Sveinbjarnarsson GK'
      },
      {
        vesselId: 266,
        vesselName: 'Arnar HU'
      },
      {
        vesselId: 271,
        vesselName: 'Orfirisey RE'
      },
      {
        vesselId: 273,
        vesselName: 'Blængur NK'
      },
      {
        vesselId: 274,
        vesselName: 'Guðmundur í Nesi'
      },
      {
        vesselId: 275,
        vesselName: 'Júlíus Geirmundsson ÍS'
      },
      {
        vesselId: 277,
        vesselName: 'Helga María AK 16'
      },
      {
        vesselId: 278,
        vesselName: 'Otto N Thorlaksson VE 5'
      },
      {
        vesselId: 279,
        vesselName: 'Málmey SK 1'
      },
      {
        vesselId: 280,
        vesselName: 'Thórunn Sveinsdóttir VE 401'
      },
      {
        vesselId: 281,
        vesselName: 'Stefnir ÍS 28'
      },
      {
        vesselId: 282,
        vesselName: 'Björgvin EA 311'
      },
      {
        vesselId: 284,
        vesselName: 'Klakkur SK 5'
      },
      {
        vesselId: 286,
        vesselName: 'Gullver NS 12'
      },
      {
        vesselId: 287,
        vesselName: 'Ljósafell SU 70'
      },
      {
        vesselId: 289,
        vesselName: 'Sirrý ÍS 36'
      },
      {
        vesselId: 291,
        vesselName: 'Sóley Sigurjóns GK 200'
      },
      {
        vesselId: 292,
        vesselName: 'Berglín GK 300'
      },
      {
        vesselId: 293,
        vesselName: 'Múlaberg SI 22'
      },
      {
        vesselId: 294,
        vesselName: 'Anna EA305'
      },
      {
        vesselId: 295,
        vesselName: 'Johanna Gisladottir GK557'
      },
      {
        vesselId: 297,
        vesselName: 'Fjolnir GK 157'
      },
      {
        vesselId: 299,
        vesselName: 'Tjaldur SH 270'
      },
      {
        vesselId: 302,
        vesselName: 'Núpur BA 69'
      },
      {
        vesselId: 303,
        vesselName: 'Rifsnes SH 44'
      },
      {
        vesselId: 304,
        vesselName: 'Örvar SH 777'
      },
      {
        vesselId: 305,
        vesselName: 'Hrafn'
      },
      {
        vesselId: 307,
        vesselName: 'Valdimar'
      },
      {
        vesselId: 308,
        vesselName: 'Langanes'
      },
      {
        vesselId: 313,
        vesselName: 'Jón Kjartansson SU111'
      },
      {
        vesselId: 314,
        vesselName: 'Drangey'
      },
      {
        vesselId: 360,
        vesselName: 'GUÐRUN TORKELSDÓTTIR'
      },
      {
        vesselId: 566,
        vesselName: 'Tomas Torvaldsson'
      },
      {
        vesselId: 568,
        vesselName: 'Sólborg RE 27'
      },
      {
        vesselId: 572,
        vesselName: 'Bjørg'
      },
      {
        vesselId: 573,
        vesselName: 'Breki VE61'
      },
      {
        vesselId: 575,
        vesselName: 'Páll Pálsson IS102'
      },
      {
        vesselId: 717,
        vesselName: 'Sólberg'
      },
      {
        vesselId: 722,
        vesselName: 'Thórsnes SH109'
      },
      {
        vesselId: 723,
        vesselName: 'Sighvatur'
      },
      {
        vesselId: 724,
        vesselName: 'Akurey'
      },
      {
        vesselId: 725,
        vesselName: 'Kaldbakur'
      },
      {
        vesselId: 726,
        vesselName: 'Bjorgulfur'
      },
      {
        vesselId: 728,
        vesselName: 'Magnús'
      },
      {
        vesselId: 729,
        vesselName: 'Sigurður Ólafsson'
      },
      {
        vesselId: 730,
        vesselName: 'Grímsnes'
      },
      {
        vesselId: 731,
        vesselName: 'Kristrún'
      },
      {
        vesselId: 733,
        vesselName: 'Viðey'
      },
      {
        vesselId: 734,
        vesselName: 'Hamar'
      },
      {
        vesselId: 735,
        vesselName: 'Jóhanna Gísladóttir GK357'
      },
      {
        vesselId: 736,
        vesselName: 'Brynjólfur'
      },
      {
        vesselId: 737,
        vesselName: 'Bylgja'
      },
      {
        vesselId: 738,
        vesselName: 'Kap'
      },
      {
        vesselId: 740,
        vesselName: 'Friðrik Sigurðsson'
      },
      {
        vesselId: 741,
        vesselName: 'Jon a Hofi'
      },
      {
        vesselId: 1302,
        vesselName: 'Jokull ÞH-299'
      },
      {
        vesselId: 1531,
        vesselName: 'Runólfur SH 135'
      },
      {
        vesselId: 1532,
        vesselName: 'Sturla GK12'
      },
      {
        vesselId: 1533,
        vesselName: 'Pálína Þórunn GK-49'
      },
      {
        vesselId: 1534,
        vesselName: 'Thorir'
      },
      {
        vesselId: 1535,
        vesselName: 'DRANGAVIK'
      },
      {
        vesselId: 1536,
        vesselName: 'DALA-RAFN'
      },
      {
        vesselId: 1539,
        vesselName: 'TINDUR  -ÍS235'
      },
      {
        vesselId: 1540,
        vesselName: 'HRINGUR'
      },
      {
        vesselId: 1543,
        vesselName: 'FRODI II'
      },
      {
        vesselId: 1545,
        vesselName: 'VESTRI'
      },
      {
        vesselId: 1547,
        vesselName: 'Vestmanney'
      },
      {
        vesselId: 1550,
        vesselName: 'FARSAELL SH-30'
      },
      {
        vesselId: 1551,
        vesselName: 'Sigurborg SH-12'
      },
      {
        vesselId: 1556,
        vesselName: 'FROSTI'
      },
      {
        vesselId: 1574,
        vesselName: 'Vordur'
      },
      {
        vesselId: 1602,
        vesselName: 'Sigurfari'
      },
      {
        vesselId: 1603,
        vesselName: 'Erling'
      },
      {
        vesselId: 1605,
        vesselName: 'SAXHAMAR'
      },
      {
        vesselId: 1607,
        vesselName: 'KAP II'
      },
      {
        vesselId: 1613,
        vesselName: 'FRAR'
      },
      {
        vesselId: 1650,
        vesselName: 'Skinney SF 20'
      },
      {
        vesselId: 1690,
        vesselName: 'Bergey VE 144'
      },
      {
        vesselId: 1691,
        vesselName: 'Siggi Bjarna GK 5'
      },
      {
        vesselId: 1692,
        vesselName: 'Benni Sæm GK 26'
      },
      {
        vesselId: 1796,
        vesselName: 'Askell'
      },
      {
        vesselId: 1863,
        vesselName: 'Hardbakur'
      },
      {
        vesselId: 2001,
        vesselName: 'Pall Jónsson GK-7'
      },
      {
        vesselId: 2545,
        vesselName: 'Thinganes SF-25'
      },
      {
        vesselId: 2547,
        vesselName: 'Steinunn SF 10'
      },
      {
        vesselId: 3822,
        vesselName: 'Vilhelm Thorsteinsson'
      },
      {
        vesselId: 3823,
        vesselName: 'Borkur'
      },
      {
        vesselId: 3856,
        vesselName: 'Baldvin Njálsson'
      },
      {
        vesselId: 3955,
        vesselName: 'Oddeyrin'
      }
    ]
  },
  {
    id: 'f46721aa79f9',
    startDate: new Date('2020-01-01T00:00:00'),
    endDate: new Date('2020-12-29T23:59:59'),
    vessels: [
      {
        vesselId: 1529,
        vesselName: 'Odd Lundberg'
      }
    ]
  },
  {
    id: 'de76ecb12b75',
    startDate: new Date('2021-01-01T00:00:00'),
    endDate: new Date('2021-12-29T23:59:59'),
    vessels: [
      {
        vesselId: 1529,
        vesselName: 'Odd Lundberg'
      }
    ]
  },
  {
    id: '674bd3c72222',
    startDate: new Date('2021-02-01T00:00:00'),
    endDate: new Date('2021-11-01T23:59:59'),
    vessels: [
      {
        vesselId: 3802,
        vesselName: 'Antarctic Provider'
      }
    ]
  },
  {
    id: '75eeb4a7a27a',
    startDate: new Date('2022-04-13T00:00:00'),
    endDate: new Date('2022-04-14T08:59:59'),
    vessels: [
      {
        vesselId: 955,
        vesselName: 'VESTURBÚGVIN'
      },
      {
        vesselId: 912,
        vesselName: 'EYSTURBÚGVIN'
      }
    ]
  },
  {
    id: 'ecd1d30c0364',
    startDate: new Date('2022-04-01T00:00:00'),
    endDate: new Date('2022-04-22T23:59:59'),
    vessels: [
      {
        vesselId: 8,
        vesselName: 'Fagraberg'
      },
      {
        vesselId: 9,
        vesselName: 'Finnur Fríði'
      },
      {
        vesselId: 10,
        vesselName: 'JUPITER (FO)'
      },
      {
        vesselId: 11,
        vesselName: 'Nordborg'
      },
      {
        vesselId: 13,
        vesselName: 'Tróndur í Gøtu'
      },
      {
        vesselId: 14,
        vesselName: 'Tummas T'
      },
      {
        vesselId: 15,
        vesselName: 'Norðingur'
      },
      {
        vesselId: 113,
        vesselName: 'Ingrid Majala 2'
      },
      {
        vesselId: 118,
        vesselName: 'FRANTSEN JUNIOR'
      },
      {
        vesselId: 124,
        vesselName: 'Nordfisk'
      },
      {
        vesselId: 125,
        vesselName: 'Haraldson'
      },
      {
        vesselId: 126,
        vesselName: 'Kvannøy'
      },
      {
        vesselId: 127,
        vesselName: 'Senior (NO)'
      },
      {
        vesselId: 130,
        vesselName: 'Rødholmen'
      },
      {
        vesselId: 131,
        vesselName: 'Øygarden'
      },
      {
        vesselId: 132,
        vesselName: 'M. Ytterstad'
      },
      {
        vesselId: 133,
        vesselName: 'Selvåg Senior'
      },
      {
        vesselId: 140,
        vesselName: 'Trønderbas'
      },
      {
        vesselId: 141,
        vesselName: 'Svanaug Elise'
      },
      {
        vesselId: 142,
        vesselName: 'Nybo'
      },
      {
        vesselId: 145,
        vesselName: 'Birita'
      },
      {
        vesselId: 146,
        vesselName: 'Havstål'
      },
      {
        vesselId: 156,
        vesselName: 'Svanur RE 45'
      },
      {
        vesselId: 157,
        vesselName: 'Fiskeskjer'
      },
      {
        vesselId: 158,
        vesselName: 'Teigenes'
      },
      {
        vesselId: 159,
        vesselName: 'Leinebjørn'
      },
      {
        vesselId: 160,
        vesselName: 'Rockall'
      },
      {
        vesselId: 161,
        vesselName: 'Kings Bay'
      },
      {
        vesselId: 162,
        vesselName: 'Eros'
      },
      {
        vesselId: 163,
        vesselName: 'Gollenes'
      },
      {
        vesselId: 164,
        vesselName: 'Smaragd (NO)'
      },
      {
        vesselId: 165,
        vesselName: 'Rogne'
      },
      {
        vesselId: 167,
        vesselName: 'Sjøbris'
      },
      {
        vesselId: 168,
        vesselName: 'Christina E'
      },
      {
        vesselId: 169,
        vesselName: 'Andrea L'
      },
      {
        vesselId: 170,
        vesselName: 'Herøyhav'
      },
      {
        vesselId: 174,
        vesselName: 'Katrin Jóhanna'
      },
      {
        vesselId: 177,
        vesselName: 'Ingrid Majala'
      },
      {
        vesselId: 178,
        vesselName: 'Fiskebank'
      },
      {
        vesselId: 179,
        vesselName: 'Dyrnesvåg'
      },
      {
        vesselId: 181,
        vesselName: 'Sæbjørn'
      },
      {
        vesselId: 182,
        vesselName: 'Vestfart'
      },
      {
        vesselId: 183,
        vesselName: 'Fiskebas'
      },
      {
        vesselId: 186,
        vesselName: 'Fonnes'
      },
      {
        vesselId: 187,
        vesselName: 'Harvest'
      },
      {
        vesselId: 188,
        vesselName: 'RADEK'
      },
      {
        vesselId: 189,
        vesselName: 'Vendla'
      },
      {
        vesselId: 191,
        vesselName: 'Knester'
      },
      {
        vesselId: 193,
        vesselName: 'Vestviking'
      },
      {
        vesselId: 195,
        vesselName: 'Manon'
      },
      {
        vesselId: 198,
        vesselName: 'Gardar'
      },
      {
        vesselId: 199,
        vesselName: 'H. Østervold'
      },
      {
        vesselId: 200,
        vesselName: 'Talbor'
      },
      {
        vesselId: 201,
        vesselName: 'Malene S'
      },
      {
        vesselId: 202,
        vesselName: 'Birkeland'
      },
      {
        vesselId: 203,
        vesselName: 'Bømmelfjord'
      },
      {
        vesselId: 204,
        vesselName: 'Østerbris'
      },
      {
        vesselId: 205,
        vesselName: 'Alsey VE 2'
      },
      {
        vesselId: 208,
        vesselName: 'NORDERVEG'
      },
      {
        vesselId: 209,
        vesselName: 'Storeknut'
      },
      {
        vesselId: 210,
        vesselName: 'Trygvason'
      },
      {
        vesselId: 211,
        vesselName: 'Lønningen'
      },
      {
        vesselId: 212,
        vesselName: 'Elisabeth'
      },
      {
        vesselId: 213,
        vesselName: 'Bømmelbas'
      },
      {
        vesselId: 214,
        vesselName: 'Brennholm'
      },
      {
        vesselId: 219,
        vesselName: 'Havdrøn'
      },
      {
        vesselId: 220,
        vesselName: 'Ligrunn'
      },
      {
        vesselId: 221,
        vesselName: 'Morten Einar'
      },
      {
        vesselId: 224,
        vesselName: 'Hargun'
      },
      {
        vesselId: 225,
        vesselName: 'Røttingøy'
      },
      {
        vesselId: 226,
        vesselName: 'Krossfjord'
      },
      {
        vesselId: 227,
        vesselName: 'Havglans'
      },
      {
        vesselId: 228,
        vesselName: 'Roaldsen'
      },
      {
        vesselId: 229,
        vesselName: 'Sydvest'
      },
      {
        vesselId: 230,
        vesselName: 'Vea'
      },
      {
        vesselId: 239,
        vesselName: 'Venus'
      },
      {
        vesselId: 240,
        vesselName: 'Beitir'
      },
      {
        vesselId: 241,
        vesselName: 'Vikingur'
      },
      {
        vesselId: 242,
        vesselName: 'Bardi'
      },
      {
        vesselId: 243,
        vesselName: 'Bjarni Ólafsson'
      },
      {
        vesselId: 244,
        vesselName: 'Adalsteinn Jonsson'
      },
      {
        vesselId: 245,
        vesselName: 'Hoffell'
      },
      {
        vesselId: 246,
        vesselName: 'Heimaey'
      },
      {
        vesselId: 247,
        vesselName: 'Vestmenningur'
      },
      {
        vesselId: 248,
        vesselName: 'Sigurdur'
      },
      {
        vesselId: 250,
        vesselName: 'Hákon'
      },
      {
        vesselId: 251,
        vesselName: 'Jón Kjartansson SU311'
      },
      {
        vesselId: 252,
        vesselName: 'Ísleifur'
      },
      {
        vesselId: 254,
        vesselName: 'Huginn'
      },
      {
        vesselId: 257,
        vesselName: 'Ásgrímur Halldórsson'
      },
      {
        vesselId: 258,
        vesselName: 'Jona Edvalds'
      },
      {
        vesselId: 260,
        vesselName: 'Sighvatur Bjarnarson VE'
      },
      {
        vesselId: 310,
        vesselName: 'Ango'
      },
      {
        vesselId: 311,
        vesselName: 'Høgaberg'
      },
      {
        vesselId: 312,
        vesselName: 'Borgarin'
      },
      {
        vesselId: 313,
        vesselName: 'Jón Kjartansson SU111'
      },
      {
        vesselId: 346,
        vesselName: 'Gambler'
      },
      {
        vesselId: 357,
        vesselName: 'Hoyvík'
      },
      {
        vesselId: 359,
        vesselName: 'Arctic Voyager'
      },
      {
        vesselId: 360,
        vesselName: 'GUÐRUN TORKELSDÓTTIR'
      },
      {
        vesselId: 363,
        vesselName: 'Isafold'
      },
      {
        vesselId: 364,
        vesselName: 'Suðurey'
      },
      {
        vesselId: 365,
        vesselName: 'Asbjørn'
      },
      {
        vesselId: 366,
        vesselName: 'Cattleya'
      },
      {
        vesselId: 367,
        vesselName: 'Herøyfjord'
      },
      {
        vesselId: 368,
        vesselName: 'West Ocean'
      },
      {
        vesselId: 369,
        vesselName: 'Gøtunes'
      },
      {
        vesselId: 370,
        vesselName: 'Slaatterøy'
      },
      {
        vesselId: 371,
        vesselName: 'Beinur'
      },
      {
        vesselId: 372,
        vesselName: 'Ceton'
      },
      {
        vesselId: 373,
        vesselName: 'Themis'
      },
      {
        vesselId: 419,
        vesselName: 'SUNBEAM'
      },
      {
        vesselId: 421,
        vesselName: 'ANTARCTIC II'
      },
      {
        vesselId: 422,
        vesselName: 'CHALLENGE'
      },
      {
        vesselId: 423,
        vesselName: 'Altaire'
      },
      {
        vesselId: 424,
        vesselName: 'CHRIS ANDRA'
      },
      {
        vesselId: 425,
        vesselName: 'Christina S'
      },
      {
        vesselId: 426,
        vesselName: 'QUANTUS'
      },
      {
        vesselId: 427,
        vesselName: 'Endre Dyrøy'
      },
      {
        vesselId: 430,
        vesselName: 'HAVILAH'
      },
      {
        vesselId: 431,
        vesselName: 'Gerda Marie'
      },
      {
        vesselId: 432,
        vesselName: 'Antares'
      },
      {
        vesselId: 433,
        vesselName: 'Voyager'
      },
      {
        vesselId: 434,
        vesselName: 'Pathway'
      },
      {
        vesselId: 435,
        vesselName: 'Grateful'
      },
      {
        vesselId: 436,
        vesselName: 'Stefanie M (UK)'
      },
      {
        vesselId: 438,
        vesselName: 'Wiron 5'
      },
      {
        vesselId: 476,
        vesselName: 'FRANK BONEFAAS'
      },
      {
        vesselId: 579,
        vesselName: 'WIRON 6'
      },
      {
        vesselId: 709,
        vesselName: 'Lønnøy'
      },
      {
        vesselId: 714,
        vesselName: 'Nordsjøbas'
      },
      {
        vesselId: 738,
        vesselName: 'Kap '
      },
      {
        vesselId: 847,
        vesselName: 'Serene'
      },
      {
        vesselId: 954,
        vesselName: 'Research'
      },
      {
        vesselId: 958,
        vesselName: 'Ocean Star'
      },
      {
        vesselId: 1009,
        vesselName: 'Strand Senior'
      },
      {
        vesselId: 1010,
        vesselName: 'Taits'
      },
      {
        vesselId: 1095,
        vesselName: 'Rav'
      },
      {
        vesselId: 1529,
        vesselName: 'Odd Lundberg'
      },
      {
        vesselId: 1530,
        vesselName: 'Cetus'
      },
      {
        vesselId: 1549,
        vesselName: 'Adenia'
      },
      {
        vesselId: 1555,
        vesselName: 'Zephyr'
      },
      {
        vesselId: 1607,
        vesselName: 'KAP II'
      },
      {
        vesselId: 1943,
        vesselName: 'Gunnar Langva'
      },
      {
        vesselId: 1999,
        vesselName: 'Charisma'
      },
      {
        vesselId: 2117,
        vesselName: 'Lunar Bow'
      },
      {
        vesselId: 2929,
        vesselName: 'MOKSTEIN'
      },
      {
        vesselId: 3479,
        vesselName: 'Resolute'
      },
      {
        vesselId: 3772,
        vesselName: 'Libas'
      },
      {
        vesselId: 3816,
        vesselName: 'Havskjer'
      },
      {
        vesselId: 3822,
        vesselName: 'Vilhelm Thorsteinsson'
      },
      {
        vesselId: 3823,
        vesselName: 'Borkur'
      },
      {
        vesselId: 3829,
        vesselName: 'Ruth'
      },
      {
        vesselId: 3848,
        vesselName: 'Hardhaus'
      },
      {
        vesselId: 4001,
        vesselName: 'Vikingbank'
      },
      {
        vesselId: 4544,
        vesselName: 'Christian Í Grótinum'
      },
      {
        vesselId: 625,
        vesselName: 'Kapitan Demidenko'
      },
      {
        vesselId: 628,
        vesselName: 'Karelia'
      },
      {
        vesselId: 631,
        vesselName: 'Kapitan Nazin'
      },
      {
        vesselId: 643,
        vesselName: 'Mekhanik Sergey Agapov'
      },
      {
        vesselId: 995,
        vesselName: 'Boris Syromyatnikov'
      },
      {
        vesselId: 996,
        vesselName: 'ESTER'
      },
      {
        vesselId: 997,
        vesselName: 'Yantarnyy'
      },
      {
        vesselId: 998,
        vesselName: 'Zamoskvorechye'
      },
      {
        vesselId: 1004,
        vesselName: 'Lazurnyy'
      },
      {
        vesselId: 1005,
        vesselName: 'Kurshskaya Kosa'
      },
      {
        vesselId: 1006,
        vesselName: 'Baltiyskaya Kosa'
      },
      {
        vesselId: 1007,
        vesselName: 'Lira'
      },
      {
        vesselId: 1447,
        vesselName: 'Kapitan Sulimov'
      },
      {
        vesselId: 2359,
        vesselName: 'Oma'
      },
      {
        vesselId: 375,
        vesselName: 'ELLEN BACH'
      },
      {
        vesselId: 376,
        vesselName: 'HELEN MARY'
      },
      {
        vesselId: 382,
        vesselName: 'ANNIE HILLINA'
      },
      {
        vesselId: 383,
        vesselName: 'Maartje Theadora'
      },
      {
        vesselId: 3338,
        vesselName: 'Kristin (DE)'
      },
      {
        vesselId: 473,
        vesselName: 'DIRK DIRK'
      },
      {
        vesselId: 474,
        vesselName: 'ALIDA'
      },
      {
        vesselId: 475,
        vesselName: 'ZEELAND'
      },
      {
        vesselId: 477,
        vesselName: 'CAROLIEN'
      },
      {
        vesselId: 478,
        vesselName: 'Afrika'
      },
      {
        vesselId: 495,
        vesselName: 'WILLEM VAN DER ZWAN'
      },
      {
        vesselId: 974,
        vesselName: 'Wiron 1'
      },
      {
        vesselId: 975,
        vesselName: 'Cap Blanc'
      }
    ]
  },
  {
    id: 'c2e3a25a79f5',
    startDate: new Date('2022-05-01T00:00:00'),
    endDate: new Date('2022-05-20T08:59:59'),
    vessels: [
      {
        vesselId: 751,
        vesselName: 'Seivest'
      },
      {
        vesselId: 2341,
        vesselName: 'Aqua Merdø'
      },
      {
        vesselId: 2403,
        vesselName: 'Hordabas'
      },
      {
        vesselId: 2468,
        vesselName: 'Hordafjell'
      },
      {
        vesselId: 2470,
        vesselName: 'Seihaust'
      },
      {
        vesselId: 2480,
        vesselName: 'Taupiri'
      },
      {
        vesselId: 2483,
        vesselName: 'Taupo'
      },
      {
        vesselId: 2485,
        vesselName: 'Tauranga'
      },
      {
        vesselId: 2490,
        vesselName: 'HAUGLAKS'
      },
      {
        vesselId: 2512,
        vesselName: 'Aqua Solundoy'
      },
      {
        vesselId: 2817,
        vesselName: 'Lovart Viking'
      },
      {
        vesselId: 3004,
        vesselName: 'Csaver'
      },
      {
        vesselId: 3285,
        vesselName: 'Taumar'
      },
      {
        vesselId: 3875,
        vesselName: 'Aqua Mist'
      },
      {
        vesselId: 4311,
        vesselName: 'Volt Harvest I'
      },
      {
        vesselId: 4472,
        vesselName: 'Volt Harvest II'
      },
      {
        vesselId: 1902,
        vesselName: 'Seibas'
      },
      {
        vesselId: 2178,
        vesselName: 'Ronja Nordic'
      },
      {
        vesselId: 2179,
        vesselName: 'Ronja Supporter'
      },
      {
        vesselId: 2180,
        vesselName: 'Ronja Superior'
      },
      {
        vesselId: 2181,
        vesselName: 'Ronjafisk'
      },
      {
        vesselId: 2182,
        vesselName: 'Ronja Viking'
      },
      {
        vesselId: 2183,
        vesselName: 'Ronja Commander'
      },
      {
        vesselId: 2184,
        vesselName: 'Ronja Explorer'
      },
      {
        vesselId: 2185,
        vesselName: 'Ronja Fjord'
      },
      {
        vesselId: 2186,
        vesselName: 'Ronja Harvester'
      },
      {
        vesselId: 2187,
        vesselName: 'Ronja Sund'
      },
      {
        vesselId: 2188,
        vesselName: 'Ronja Tind'
      },
      {
        vesselId: 2189,
        vesselName: 'Ronja Strand'
      },
      {
        vesselId: 2190,
        vesselName: 'Ronja Polaris'
      },
      {
        vesselId: 2195,
        vesselName: 'Ronja Storm'
      },
      {
        vesselId: 2196,
        vesselName: 'Ronja Huon'
      },
      {
        vesselId: 2200,
        vesselName: 'Steinar Olaisen'
      },
      {
        vesselId: 2202,
        vesselName: 'Ronja Challenger'
      },
      {
        vesselId: 2203,
        vesselName: 'RO FJELL'
      },
      {
        vesselId: 2205,
        vesselName: 'Novatrans'
      },
      {
        vesselId: 2206,
        vesselName: 'Inter Nord'
      },
      {
        vesselId: 2207,
        vesselName: 'Viknatrans'
      },
      {
        vesselId: 2210,
        vesselName: 'RONJA CARRIER'
      },
      {
        vesselId: 2216,
        vesselName: 'RO CHIEF'
      },
      {
        vesselId: 2217,
        vesselName: 'Langsund'
      },
      {
        vesselId: 2218,
        vesselName: 'ROSTEIN'
      },
      {
        vesselId: 2219,
        vesselName: 'ROBRIS'
      },
      {
        vesselId: 2220,
        vesselName: 'ROBAS'
      },
      {
        vesselId: 2221,
        vesselName: 'ROHAV'
      },
      {
        vesselId: 2222,
        vesselName: 'Lifjell'
      },
      {
        vesselId: 2225,
        vesselName: 'MIGDALE'
      },
      {
        vesselId: 2234,
        vesselName: 'Havtrans'
      },
      {
        vesselId: 2235,
        vesselName: 'Steigen'
      },
      {
        vesselId: 2237,
        vesselName: 'VIKTORIA LADY'
      },
      {
        vesselId: 2238,
        vesselName: 'Veidnes'
      },
      {
        vesselId: 2287,
        vesselName: 'Aqua Kvaløy'
      },
      {
        vesselId: 2288,
        vesselName: 'Ro Fortune'
      },
      {
        vesselId: 2289,
        vesselName: 'Ronja Ocean'
      },
      {
        vesselId: 2290,
        vesselName: 'Ro Arctic'
      },
      {
        vesselId: 2291,
        vesselName: 'RO FJORD'
      },
      {
        vesselId: 2292,
        vesselName: 'RO MASTER'
      },
      {
        vesselId: 2293,
        vesselName: 'Ro North'
      },
      {
        vesselId: 2294,
        vesselName: 'Ro Server'
      },
      {
        vesselId: 2295,
        vesselName: 'Ro West'
      },
      {
        vesselId: 2333,
        vesselName: 'Namsos'
      },
      {
        vesselId: 2335,
        vesselName: 'Aqua Maløy'
      },
      {
        vesselId: 2337,
        vesselName: 'Aqua Spa'
      },
      {
        vesselId: 2339,
        vesselName: 'Aqua Skilsøy'
      },
      {
        vesselId: 2343,
        vesselName: 'Norwegian Gannet'
      },
      {
        vesselId: 2347,
        vesselName: 'Inter Caledonia'
      },
      {
        vesselId: 2437,
        vesselName: 'SEIHAV'
      },
      {
        vesselId: 2443,
        vesselName: 'SEIVÅG'
      },
      {
        vesselId: 2445,
        vesselName: 'Gåsø Jarl'
      },
      {
        vesselId: 2448,
        vesselName: 'Gåsø Freyja'
      },
      {
        vesselId: 2450,
        vesselName: 'Frøystrand'
      },
      {
        vesselId: 2451,
        vesselName: 'Sørdyrøy'
      },
      {
        vesselId: 2452,
        vesselName: 'Gåsø Viking'
      },
      {
        vesselId: 2471,
        vesselName: 'Korsholmen'
      },
      {
        vesselId: 2473,
        vesselName: 'MOWI-STAR'
      },
      {
        vesselId: 2475,
        vesselName: 'Inter Barents'
      },
      {
        vesselId: 2509,
        vesselName: 'Viking Saga'
      },
      {
        vesselId: 2532,
        vesselName: 'Aqua Transporter'
      },
      {
        vesselId: 2555,
        vesselName: 'Ro Vision'
      },
      {
        vesselId: 2560,
        vesselName: 'STEYER'
      },
      {
        vesselId: 2561,
        vesselName: 'Viktoria Viking'
      },
      {
        vesselId: 2562,
        vesselName: 'Dønnalaks'
      },
      {
        vesselId: 2872,
        vesselName: 'Marsali'
      },
      {
        vesselId: 2993,
        vesselName: 'Aqua Viking'
      },
      {
        vesselId: 2998,
        vesselName: 'SMØLA VÅG'
      },
      {
        vesselId: 3282,
        vesselName: 'Seifisk Jr.'
      },
      {
        vesselId: 3283,
        vesselName: 'Dønnland'
      },
      {
        vesselId: 3293,
        vesselName: 'HAVØRN'
      },
      {
        vesselId: 3295,
        vesselName: 'TRITON'
      },
      {
        vesselId: 3300,
        vesselName: 'SCANTANK'
      },
      {
        vesselId: 3303,
        vesselName: 'ODDØY'
      },
      {
        vesselId: 3312,
        vesselName: 'Frøy Merlin'
      },
      {
        vesselId: 3339,
        vesselName: 'Green Sea Service'
      },
      {
        vesselId: 3478,
        vesselName: 'Hydro Pioneer'
      },
      {
        vesselId: 3865,
        vesselName: 'Reisa'
      },
      {
        vesselId: 3869,
        vesselName: 'Ronja Christopher'
      },
      {
        vesselId: 3873,
        vesselName: 'Hordagut'
      },
      {
        vesselId: 3874,
        vesselName: 'Kristoffer Tronds'
      },
      {
        vesselId: 3876,
        vesselName: 'Horda Pioneer'
      },
      {
        vesselId: 3912,
        vesselName: 'Ro Venture'
      },
      {
        vesselId: 3914,
        vesselName: 'Aqua Havsøy'
      },
      {
        vesselId: 3933,
        vesselName: 'Aqua Skye'
      },
      {
        vesselId: 3934,
        vesselName: 'Aqua Gripfisk'
      },
      {
        vesselId: 3949,
        vesselName: 'Åsværfjord'
      },
      {
        vesselId: 3950,
        vesselName: 'Gåsø Høvding'
      },
      {
        vesselId: 3951,
        vesselName: 'Kristiansund'
      },
      {
        vesselId: 4026,
        vesselName: 'Grotanger'
      },
      {
        vesselId: 4055,
        vesselName: 'Bjørg Pauline'
      },
      {
        vesselId: 4211,
        vesselName: 'Aqua Homborøy'
      },
      {
        vesselId: 4319,
        vesselName: 'Ronja Nærøysund'
      },
      {
        vesselId: 4324,
        vesselName: 'Ro Sailor'
      },
      {
        vesselId: 4402,
        vesselName: 'Aqua Caledonia'
      },
      {
        vesselId: 4410,
        vesselName: 'Færøysund'
      },
      {
        vesselId: 4416,
        vesselName: 'Ronja Princess'
      },
      {
        vesselId: 4420,
        vesselName: 'Ronja Vest'
      },
      {
        vesselId: 4496,
        vesselName: 'Skrova'
      },
      {
        vesselId: 4530,
        vesselName: 'Hydro Patriot'
      },
      {
        vesselId: 4538,
        vesselName: 'Brattvær'
      }
    ]
  },
  {
    id: 'fbd07d05b1c9',
    startDate: new Date('2021-04-01T00:00:00'),
    endDate: new Date('2022-06-06T00:00:00'),
    vessels: [
      {
        vesselId: 3772,
        vesselName: 'Libas'
      },
      {
        vesselId: 3848,
        vesselName: 'Hardhaus'
      },
      {
        vesselId: 3822,
        vesselName: 'Vilhem Thorsteinsson'
      },
      {
        vesselId: 3823,
        vesselName: 'Borkur'
      }
    ]
  },
  {
    id: '0ea297316bbf',
    startDate: new Date('2022-05-06T00:00:00'),
    endDate: new Date('2022-05-09T00:00:00'),
    vessels: [
      {
        vesselId: 342,
        vesselName: 'Antarctic Sea'
      }
    ]
  }
];

export default data;
