import { L } from '../../../types/map';
import { TVesselShort, TTrackPoint } from '../../../types/vessel';

require('./L.CanvasLayer');
const cnv = (window.L as any).canvasLayer();

const scaler = 1.5;
const ROTOR = Math.PI / 180;
const HEIGHT = scaler * 14;
const WIDTH = scaler * 6;

/**
 * Returns new coordinates of the point [x, y] rotated by angle (represented by sin-cos pair)
 * around the origin [x0, y0].
 */
export function getCoordinatesRotated(
  x0: number,
  y0: number,
  x: number,
  y: number,
  cos: number,
  sin: number
): [number, number] {
  return [x0 + x * cos - y * sin, y0 + x * sin + y * cos];
}


export function markersRenderer(
  vessels: TTrackPoint[],
  map: L.Map,
  infos: TVesselShort[]
): Object {
  const obj = {
    onDrawLayer: (layer): void => {
      const ctx = layer.canvas.getContext('2d');
      ctx && ctx.clearRect(0, 0, layer.canvas.width, layer.canvas.height);
      drawVessels(vessels, layer, infos);
    }
  };

  cnv.delegate(obj).addTo(map);
  cnv.needRedraw();
  return cnv;
}

export function drawVessels(
  vessels: TTrackPoint[],
  layer,
  infos: TVesselShort[]
) {
  if (!vessels || !vessels.length) return;
  const ctx = layer.canvas.getContext('2d');

  vessels.forEach((vessel: TTrackPoint, index: number) => {
    const { heading, lat, lng } = vessel;
    const position = [lat, lng];
    const rad = (heading || 0) * ROTOR;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);

    if (!layer.bounds.contains(position)) return;
    const { x, y } = layer.layer._map.latLngToContainerPoint(position);
    const { colors, name } = infos[index];

    ctx.fillStyle = colors[0];
    ctx.beginPath();
    ctx.moveTo(...getCoordinatesRotated(x, y, 0, -HEIGHT / 2, cos, sin));
    ctx.lineTo(
      ...getCoordinatesRotated(x, y, -WIDTH / 2, -HEIGHT / 14, cos, sin)
    );
    ctx.lineTo(
      ...getCoordinatesRotated(x, y, -WIDTH / 2, HEIGHT / 2, cos, sin)
    );
    ctx.lineTo(...getCoordinatesRotated(x, y, 0, (2 * HEIGHT) / 7, cos, sin));
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = colors[1];
    ctx.beginPath();
    ctx.moveTo(...getCoordinatesRotated(x, y, 0, -HEIGHT / 2, cos, sin));
    ctx.lineTo(
      ...getCoordinatesRotated(x, y, WIDTH / 2, -HEIGHT / 14, cos, sin)
    );
    ctx.lineTo(...getCoordinatesRotated(x, y, WIDTH / 2, HEIGHT / 2, cos, sin));
    ctx.lineTo(...getCoordinatesRotated(x, y, 0, (2 * HEIGHT) / 7, cos, sin));
    ctx.closePath();
    ctx.fill();

    // Vessel title
    if (!name) return;
    ctx.fillStyle = 'black';
    ctx.font = '11px Inter, Arial, sans-serif';
    ctx.fillText(name.toUpperCase(), x - WIDTH, y + HEIGHT);
  });
}
