import { data } from './data';
import { TAnimationConfig, TVesselData, TVesselDataConfig } from '../types/data';
import { TVesselTrack, TTrackPoint, TVesselTrackPoint } from '../types/vessel';


class DataLoader {
  private readonly animationId: string;
  private readonly onSuccess: (array: TVesselData[]) => void;

  constructor(
    onSuccess: (array: TVesselData[]) => void
  ) {
    this.onSuccess = onSuccess;
    this.animationId = new URLSearchParams(window.location.search).get('animationId');
    this.loadData();
  }


  private loadData(): void {
    if (!this.animationId) {
      const msg = 'Invalid animation ID!';
      alert(msg);
      return window.console.error(msg);
    }

    const dataItem = data.find((el: TAnimationConfig) => el.id === this.animationId);

    Promise.all(dataItem.vessels.map(this.makeRequest))
      .then(this.onSuccess)
      .catch(window.console.error);
  }


  private makeRequest = (vessel: TVesselDataConfig): Promise<TVesselData> => {
    return fetch(`tracks/${this.animationId}/vessel_${vessel.vesselId}.json`)
      .then((item: Response) => item.json())
      .then((track: TVesselTrack): TVesselData => {
        const points = track.data.map((point: TVesselTrackPoint): TTrackPoint => ({
          heading: point.heading,
          lat: point.latitude,
          lng: point.longitude,
          timestamp: new Date(point.lastUpdate).getTime()
        }));

        return {
          name: vessel.vesselName,
          data: points,
          country: ''
        };
      });
  };
}


export default DataLoader;
