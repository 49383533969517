import { UPDATE_INTERVAL } from './helpers';
import { EPlayMode, EPlayRate, TTimeFlowState } from '../types/time';


class TimeFlow {
  static INITIAL_TIME_STATE = {
    currentIndex: 0,
    playMode: EPlayMode.STOP,
    playRate: EPlayRate.NORMAL
  };

  // Maximal index of the data array = length - 1;
  private readonly maxIndex: number;
  // On update callback.
  private readonly onUpdate: (state: TTimeFlowState) => void;
  // Current index of the data array, which represents the current moment.

  private currentIndex = TimeFlow.INITIAL_TIME_STATE.currentIndex;
  // The player mode.
  private playMode = TimeFlow.INITIAL_TIME_STATE.playMode;
  // The rate is normal for Play mode, and high for FastForward and Rewind.
  private playRate = TimeFlow.INITIAL_TIME_STATE.playRate;
  // Preserves the play rate when switching from play to FF\rewind and then back.
  private savedPlayRate = null;
  // Timeout ID.
  private timer;

  constructor(
    maxIndex: number,
    onUpdate: (state: TTimeFlowState) => void
  ) {
    this.maxIndex = maxIndex;
    this.onUpdate = onUpdate;
    this.update();
  }

  play = (): void => {
    this.currentIndex = this.currentIndex === 0 ? -1 : this.currentIndex;
    this.tick();
    this.timer = this.timer || setInterval(this.tick, UPDATE_INTERVAL);

    this.playRate = this.savedPlayRate || EPlayRate.NORMAL;
    this.savedPlayRate = null;
    this.playMode = EPlayMode.PLAY;
  };

  stop = (): void => {
    clearInterval(this.timer);
    this.timer = null;
    this.playMode = EPlayMode.STOP;
    this.update();
  };

  pause = (): void => {
    clearInterval(this.timer);
    this.timer = null;
    this.playMode = EPlayMode.PAUSE;
    this.update();
  };

  fastForward = (): void => {
    this.timer = this.timer || setInterval(this.tick, UPDATE_INTERVAL);
    this.savedPlayRate = this.playRate;
    this.playRate = EPlayRate.x100;
    this.playMode = EPlayMode.FAST_FORWARD;
    this.update();
  };

  rewind = (): void => {
    this.timer = this.timer || setInterval(this.tick, UPDATE_INTERVAL);
    this.savedPlayRate = this.playRate;
    this.playRate = EPlayRate.x100;
    this.playMode = EPlayMode.REWIND;
    this.update();
  };

  setPlayRate = (rate: EPlayRate): void => {
    this.savedPlayRate = null;
    this.playRate = rate;
    this.update();
  };

  setCurrentIndex = (index: number): void => {
    if (index < 0)
      this.currentIndex = 0;
    else if (index > this.maxIndex)
      this.currentIndex = this.maxIndex;
    else
      this.currentIndex = index;

    this.update();
  };

  private tick = (): void => {
    if (
      this.playMode === EPlayMode.PLAY &&
      this.currentIndex + this.playRate > this.maxIndex
    ) {
      this.currentIndex = this.maxIndex;
      return this.stop();
    }

    if (
      this.playMode === EPlayMode.FAST_FORWARD &&
      this.currentIndex + this.playRate > this.maxIndex
    ) {
      this.currentIndex = this.maxIndex;
      return this.stop();
    }

    if (
      this.playMode === EPlayMode.REWIND &&
      this.currentIndex - this.playRate < 0
    ) {
      this.currentIndex = 0;
      return this.stop();
    }

    const sign = this.playMode === EPlayMode.REWIND ? -1 : 1;
    this.currentIndex = this.currentIndex + sign * this.playRate;
    this.update();
  };

  private update(): void {
    const state = {
      currentIndex: this.currentIndex,
      playMode: this.playMode,
      playRate: this.playRate
    };
    // console.log(JSON.stringify(state, null, 2));
    this.onUpdate(state);
  }
}


export default TimeFlow;
