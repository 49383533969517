function convertDDToDMS(D, lng, minPrecision = 10000) {
  return {
    dir: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
    deg: 0 | (D < 0 ? (D = -D) : D),
    min: (0 | ((D % 1) * 60 * minPrecision)) / minPrecision,
    sec: (0 | (((D * 60) % 1) * 6000)) / 100
  };
}


window.L.Control.MouseCoordinates = window.L.Control.extend({
  options: {
    position: 'topright',
    startMessage: 'Tap/swipe to see mouse coordinates'
  },

  onAdd: function (map) {
    this._container = window.L.DomUtil.create(
      'div',
      'leaflet-control-coordinates'
    );
    this._message = window.L.DomUtil.create(
      'i',
      'leaflet-control-coordinates-message',
      this._container
    );
    this._message.innerHTML = this.options.startMessage;

    map && map.on('mousemove', this._onMouseMove, this);
    return this._container;
  },

  onRemove: function (map) {
    map.off('mousemove', this._onMouseMove);
  },

  _onMouseMove: function (e) {
    const handler = window.L.Util.throttle(this._handleMouseMove, 200, this);
    handler(e.latlng);
  },

  _handleMouseMove: function ({ lat, lng }) {
    if (lat === void 0 || lng === void 0) return null;
    const latitude = convertDDToDMS(lat, false, 100);
    const longitude = convertDDToDMS(lng, true, 100);

    const template = `
      <div class="leaflet-control-coordinates-block">
        <b>Lat:</b><span> {lat_deg}&#176; {lat_min}&#8242; {lat_dir}</span>
      </div>
      <div class="leaflet-control-coordinates-block">
        <b>Lon:</b><span> {lng_deg}&#176; {lng_min}&#8242; {lng_dir}</span>
      </div>
    `;

    const data = {
      lat_deg: latitude.deg,
      lat_min: latitude.min,
      lat_dir: latitude.dir,
      lng_deg: longitude.deg,
      lng_min: longitude.min,
      lng_dir: longitude.dir
    };
    this._container.innerHTML = window.L.Util.template(template, data);
  }
});

export function mouseCoordinates(options) {
  return new window.L.Control.MouseCoordinates(options);
}
