import React from 'react';

import './TrackPlayer.css';
import { getCurrentMs, getMaxIndex } from '../../other/helpers';
import Map from '../Map/Map';
import PlayerControl from '../PlayerControl/PlayerControl';
import TimeFlow from '../../other/TimeFlow';

import { TPlayerState, TTimeFlowState } from '../../types/time';
import { TTrackPoint } from '../../types/vessel';
import { TVesselData } from '../../types/data';

type Props = {
  endTime: number;
  startTime: number;
  vessels: TVesselData[];
};


class TrackPlayer extends React.Component<Props, TPlayerState> {
  private flow: TimeFlow;

  state: TPlayerState = {
    ...TimeFlow.INITIAL_TIME_STATE,
    endTime: 0,
    startTime: 0
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<TPlayerState>): void {
    const { endTime, startTime } = this.state;

    if (this.props.endTime !== prevProps.endTime) {
      this.setState({
        endTime: this.props.endTime,
        startTime: this.props.startTime
      }, this.initFlow);
    }

    if (prevState.endTime !== endTime ||
      prevState.startTime !== startTime
    ) {
      this.initFlow();
    }
  }

  initFlow(): void {
    const { endTime, startTime } = this.state;
    const maxIndex = getMaxIndex(startTime, endTime);
    this.flow = new TimeFlow(maxIndex, this.update);
  }

  getDataSlice = (v: TVesselData): TVesselData => {
    const { currentIndex, startTime } = this.state;
    const currentTime = getCurrentMs(startTime, currentIndex);

    const points = v.data.filter((point: TTrackPoint) =>
      point.timestamp >= startTime && point.timestamp <= currentTime
    );

    return {
      ...v,
      data: points
    };
  };

  update = (state: TTimeFlowState): void => this.setState(state);

  handleStartEnd = (start: number, end: number) => this.setState({
    endTime: end,
    startTime: start
  });

  render() {
    const { endTime, startTime, vessels } = this.props;
    const tracks = vessels.map(this.getDataSlice);

    return (
      <div className="TrackPlayer">
        <Map tracks={tracks} />

        <PlayerControl
          flow={this.flow}
          maxTime={endTime}
          minTime={startTime}
          onStartEnd={this.handleStartEnd}
          playerState={this.state}
        />
      </div>
    );
  }

}


export default TrackPlayer;
