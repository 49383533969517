import React from 'react';
import DatePicker from 'react-datepicker';

import './DateRange.css';
import { getTimeOffset } from '../../other/helpers';

type Props = {
  endTime: number;
  maxTime: number;
  minTime: number;
  onStartEnd: (startTime: number, endTime: number) => void;
  startTime: number;
};

function adjustInput(ms: number): number {
  return ms + getTimeOffset();
}


function DateRange(props: Props) {
  const { endTime, maxTime, minTime, onStartEnd, startTime } = props;
  const end = adjustInput(endTime);
  const max = adjustInput(maxTime);
  const min = adjustInput(minTime);
  const start = adjustInput(startTime);

  const handleEnd = (value: Date) => {
    const adj = value.getTime() - getTimeOffset();
    onStartEnd(startTime, adj > maxTime ? maxTime : adj);
  };

  const handleStart = (value: Date) => {
    const adj = value.getTime() - getTimeOffset();
    onStartEnd(adj < minTime ? minTime : adj, end);
  };

  return (
    <section className="DateRange">
      <div className="DateRange__block">
        <span className="DateRange__label">Start</span>

        <DatePicker
          dateFormat="yyyy MMM dd, HH:mm"
          maxDate={new Date(end)}
          minDate={new Date(min)}
          name="startTime"
          onChange={handleStart}
          selected={new Date(start)}
          selectsStart
          showTimeSelect
        />
      </div>

      <div className="DateRange__block">
        <span className="DateRange__label">End</span>

        <DatePicker
          dateFormat="yyyy MMM dd, HH:mm"
          maxDate={new Date(max)}
          minDate={new Date(start)}
          name="endTime"
          onChange={handleEnd}
          selected={new Date(end)}
          selectsEnd
          showTimeSelect
        />
      </div>
    </section>
  );
}


export default DateRange;
